<template>
  <a-row>
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
    >
      <h1>Hotel Information</h1>
    </a-col>
  </a-row>
  <a-row style="padding-bottom: 30px">
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="Hotel Name" name="hotelName">
          <a-input
            v-model:value="formState.hotelName"
            placeholder="Your hotel name"
            size="large"
          />
        </a-form-item>
        <a-form-item
          label="SHA Code"
          name="shaCode"
          v-if="props.actionType === 'add'"
        >
          <a-input
            v-model:value="formState.shaCode"
            placeholder="X9999"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Hotel Address" name="address">
          <a-textarea
            v-model:value="formState.address"
            class="large"
            :rows="4"
            placeholder="Your hotel address"
          />
        </a-form-item>
        <a-form-item label="Hotel Phone Number" name="hotelPhoneNumber">
          <a-input
            v-model:value="formState.hotelPhoneNumber"
            placeholder="Your hotel phone number"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Hotel Email" name="hotelEmail">
          <a-input
            v-model:value="formState.hotelEmail"
            placeholder="Your hotel email"
            size="large"
            type="email"
          />
        </a-form-item>
        <a-form-item label="Hotel Facebook" name="facebook">
          <a-input
            v-model:value="formState.facebook"
            placeholder="Your link hotel facebook"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Hotel Line OA" name="line">
          <a-input
            v-model:value="formState.line"
            placeholder="Your hotel line OA"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Hotel Website" name="websiteUrl">
          <a-input
            v-model:value="formState.websiteUrl"
            placeholder="Your hotel website"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Hotel zone" name="zoneId">
          <a-select
            v-model:value="formState.zoneId"
            size="large"
            placeholder="Please select your zone"
          >
            <a-select-option value="" disabled>
              Please select your zone
            </a-select-option>

            <a-select-option
              v-for="zone in zones"
              :key="zone.id"
              :value="zone.id"
            >
              {{ zone.zoneName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Hotel Location (Embed code map)" name="embededMap">
          <a-textarea
            v-model:value="formState.embededMap"
            class="large"
            :rows="4"
            placeholder="Your hotel location (Embed code map)"
          />
        </a-form-item>

        <div style="padding-bottom: 10px">
          <p>
            <span style="color: #ff4d4f">*</span> Hotel Logo (JPEG, PNG and 1:1
            size)
          </p>
          <div style="padding-bottom: 10px" :v-if="oldImg.logoUrl">
            <a-image :width="200" :src="oldImg.logoUrl" />
          </div>
          <ImgUpload
            title="กดเพื่ออัพโหลด"
            bucketName="evenmoreamazing"
            @callback="setImageLogoUrl"
            :resolution="600 / 600"
          />
        </div>

        <div>
          <p>
            <span style="color: #ff4d4f">*</span> Hotel Business License (JPEG,
            PNG)
          </p>
          <div style="padding-bottom: 10px" :v-if="oldImg.certificateUrl">
            <a-image :width="200" :src="oldImg.certificateUrl" />
          </div>
          <ImgUpload
            title="กดเพื่ออัพโหลด"
            bucketName="hotelcertificate"
            @callback="setImageCertificateUrl"
          />
        </div>

        <a-row justify="center">
          <a-button
            type="primary"
            size="large"
            @click="onSubmit"
            :disabled="disableSubmitButton"
            class="main-button"
          >
            <template #icon>
              <RightSquareOutlined />
            </template>
            Submit
          </a-button>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, reactive, toRaw, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isEmpty } from "lodash";
import { message } from "ant-design-vue";
import ImgUpload from "../../components/ImgUpload";
import { adminRoot } from "../../constant/config";
import { RightSquareOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: ["actionType"],
  emits: ["callback"],
  components: { ImgUpload, RightSquareOutlined },
  setup(props, { emit }) {
    const store = useStore();
    const zones = ref([]);
    const formRef = ref();
    const disableSubmitButton = ref(false);
    const router = useRouter();
    const oldImg = ref({
      logoUrl: false,
      certificateUrl: false,
    });

    const formState = reactive({
      hotelName: "",
      shaCode: "",
      logoUrl: false,
      hotelCertificate: {
        bucketName: false,
        fileName: false,
      },
      address: "",
      hotelPhoneNumber: "",
      hotelEmail: "",
      facebook: "",
      line: "",
      websiteUrl: "",
      zoneId: null,
      embededMap: "",

      // to-do: เสร็จแล้วลบด้วย
      // hotelName: "Bitkub Hotel",
      // shaCode: "BH007",
      // logoUrl: false,
      // hotelCertificate: {
      //   bucketName: false,
      //   fileName: false,
      // },
      // address: "Unicorn in Thailand",
      // hotelPhoneNumber: "0811111111",
      // hotelEmail: "top007@bitkub.com",
      // facebook: "https://www.facebook.com/toppjirayutofficial",
      // line: "@topp.jirayu",
      // websiteUrl: "https://www.bitkub.com/",
      // zoneId: null,
      // embededMap: "test",
    });

    onMounted(async () => {
      // ตรวจสอบว่ามี Hotel หรือไม่ ถ้ามีให้ไปหน้า dashboard
      await store.dispatch(`auth/profile`);
      const profile = await store.getters["auth/currentUser"];
      const hotelInfo = profile.profile.hotelId;

      if (hotelInfo && props.actionType === "edit") {
        // ดึงข้อมูลใส่ใน input
        formState.hotelName = hotelInfo.hotelName;
        formState.shaCode = hotelInfo.shaCode;
        formState.logoUrl = hotelInfo.logoUrl;
        formState.hotelCertificate = {
          bucketName: hotelInfo.hotelCertificate.bucketName,
          fileName: hotelInfo.hotelCertificate.fileName,
        };
        formState.address = hotelInfo.address;
        formState.hotelPhoneNumber = hotelInfo.phoneNumber;
        formState.hotelEmail = hotelInfo.email;
        formState.facebook = hotelInfo.facebook;
        formState.line = hotelInfo.line;
        formState.websiteUrl = hotelInfo.websiteUrl;
        formState.zoneId = hotelInfo.zoneId;
        formState.embededMap = hotelInfo.embededMap;

        // Set img เพื่อไปแสดงใน html
        oldImg.value.logoUrl = hotelInfo.logoUrl;
        const publicUrl = await store.dispatch(`bucket/getPrivateUrl`, {
          bucketName: hotelInfo.hotelCertificate.bucketName,
          fileName: hotelInfo.hotelCertificate.fileName,
        });
        oldImg.value.certificateUrl = publicUrl.data.url;
      } else if (props.actionType === "add") {
        if (profile.profile.hotelId) {
          setTimeout(() => {
            router.push(adminRoot);
          }, 200);
        }
      } else {
        // กรณี register มาแล้วแต่ไม่ได้กรอก fill up
      }

      // ดึง Zone มาแสดง
      const dataZone = await store.dispatch("zone/getZoneList");
      if (!isEmpty(dataZone.data)) {
        zones.value = dataZone.data.map((data) => {
          return {
            id: data.id,
            zoneName: data.zoneName,
          };
        });
      } else {
        console.log("dataZone is empty");
        zones.value = null;
      }
    });

    watch(
      () => store.getters["auth/currentUser"],
      (val) => {
        if (props.actionType === "edit") {
          // todo : ทำอะไรต่อ
        } else {
          if (val.profile && val.profile.hotelId) {
            setTimeout(() => {
              router.push(adminRoot);
            }, 200);
          }
        }
      }
    );

    /* -------------
    - Upload File -
    ------------- */
    const setImageLogoUrl = (res) => {
      formState.logoUrl = res.readUrl;
    };
    const setImageCertificateUrl = (res) => {
      formState.hotelCertificate.bucketName = res.bucketName;
      formState.hotelCertificate.fileName = res.fileName;
    };
    /* ------------- */

    const rules = {
      hotelName: [{ required: true, message: "Hotel name is required" }],
      shaCode: [{ required: true, message: "SHA code is required" }],
      address: [{ required: true, message: "Address is required" }],
      hotelPhoneNumber: [
        { required: true, message: "Hotel phone number is required" },
        {
          max: 10,
          message: "Phone number cannot be longer than 10 characters",
        },
        {
          validator(word, value) {
            if (!isEmpty(value)) {
              const re = /^[0-9]*$/;

              if (
                re.test(`${value}`.toLowerCase()) &&
                `${value}`.length <= 10 &&
                `${value}`.length > 0
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                "Invalid phone number and phone number must be 10 characters"
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      hotelEmail: [
        { required: true, message: "Hotel email is required" },
        {
          validator(_, value) {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (value.length != 0 && re.test(`${value}`.toLowerCase())) {
              return Promise.resolve();
            }
            return Promise.reject("Invalid Email");
          },
        },
      ],
      websiteUrl: [{ required: true, message: "Hotel website is required" }],
      zoneId: [
        {
          required: true,
          message: "Please select your hotel zone",
        },
      ],
      embededMap: [
        { required: true, message: "Hotel embeded map is required" },
      ],
    };

    const onSubmit = () => {
      if (
        formState.logoUrl == false ||
        formState.hotelCertificate.bucketName == false ||
        formState.hotelCertificate.fileName == false
      ) {
        message.error(
          "Please upload hotel logo and hotel business license (JPEG, PNG)."
        );
        return;
      }

      formRef.value
        .validate()
        .then(async () => {
          try {
            if (props.actionType === "edit") {
              await store.dispatch(`auth/updateFillUp`, formState);
              // Set img เพื่อไปแสดงใน html
              oldImg.value.logoUrl = formState.logoUrl;
              const certificateUrl = await store.dispatch(
                `bucket/getPrivateUrl`,
                {
                  bucketName: formState.hotelCertificate.bucketName,
                  fileName: formState.hotelCertificate.fileName,
                }
              );
              oldImg.value.certificateUrl = certificateUrl.data.url;
            } else {
              disableSubmitButton.value = true; // disable ปุ่ม
              await store.dispatch(`auth/fillUp`, formState);
            }
            await store.dispatch(`auth/profile`); // Update ข้อมูลใหม่ใน state
            message.success("Fill up hotel information is success.");
          } catch (e) {
            console.log(e);
            disableSubmitButton.value = false;
            message.error(
              "This is an error message, This may be due to duplicate SHA Code or may not be correct, please try again."
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    return {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      formState,
      formRef,
      onSubmit,
      rules,
      zones,
      setImageLogoUrl,
      setImageCertificateUrl,
      disableSubmitButton,
      adminRoot,
      oldImg,
      props,
    };
  },
});
</script>

<style scoped>
.main-button {
  background: #57b947;
  border-color: #57b947;
  color: #ffffff;
  font-weight: bold;
}
.main-button[disabled] {
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  font-weight: bold;
}

.large {
  padding: 6.5px 11px;
  font-size: 16px;
}
</style>
