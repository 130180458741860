<template>
  <a-row>
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
      style="padding-bottom: 10px"
    >
      <h1>Account Information</h1>
    </a-col>
  </a-row>
  <a-row style="padding-bottom: 30px">
    <a-col
      :md="{ span: 12, offset: 6 }"
      :sm="{ span: 16, offset: 4 }"
      :xs="{ span: 20, offset: 2 }"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="First name" name="firstname">
          <a-input
            v-model:value="formState.firstname"
            placeholder="Your first name"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Last name" name="lastname">
          <a-input
            v-model:value="formState.lastname"
            placeholder="Your Last name"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Position" name="position">
          <a-input
            v-model:value="formState.position"
            placeholder="Your position in hotel"
            size="large"
          />
        </a-form-item>

        <a-form-item label="Email" name="email">
          <a-input
            v-model:value="formState.email"
            placeholder="Your email"
            size="large"
            type="email"
          />
        </a-form-item>

        <a-form-item label="Phone number (Username)">
          <a-input
            v-model:value="formState.phoneNumber"
            disabled="true"
            placeholder="Your phone number"
            size="large"
          />
        </a-form-item>

        <a-row style="justify-content: center">
          <a-button
            type="primary"
            size="large"
            @click="onSubmit"
            class="main-button"
          >
            <template #icon>
              <RightSquareOutlined />
            </template>
            Submit
          </a-button>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, reactive, toRaw, ref, onMounted } from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { SmileOutlined, RightSquareOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    SmileOutlined,
    RightSquareOutlined,
  },
  setup() {
    const store = useStore();
    const formRef = ref();

    const formState = reactive({
      firstname: "",
      lastname: "",
      email: "",
      position: "",

      // to-do: เสร็จแล้วลบด้วย
      // firstname: "Kai",
      // lastname: "noi",
      // email: "kainoi@test.com",
      // position: "GM",
    });

    onMounted(async () => {
      await store.dispatch(`auth/profile`);
      const profile = await store.getters["auth/currentUser"];
      const profileInfo = profile.profile;

      formState.firstname = profileInfo.firstname;
      formState.lastname = profileInfo.lastname;
      formState.position = profileInfo.position;
      formState.email = profileInfo.email;
      formState.phoneNumber = profileInfo.username;
    });

    const rules = {
      firstname: [{ required: true, message: "Your first name is required" }],
      lastname: [{ required: true, message: "Your last name is required" }],
      email: [
        { required: true, message: "Your email is required" },
        {
          validator(_, value) {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (value.length != 0 && re.test(`${value}`.toLowerCase())) {
              return Promise.resolve();
            }
            return Promise.reject("Invalid Email");
          },
        },
      ],
      position: [{ required: true, message: "Your position is required" }],
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          try {
            await store.dispatch(`auth/updateProfile`, formState);
            await store.dispatch(`auth/profile`);
            message.success("Register success.");
          } catch (e) {
            console.log(e);
            message.error("This is an error message, please try again.");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    return {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      formState,
      formRef,
      onSubmit,
      rules,
    };
  },
});
</script>

<style scoped>
.main-button {
  background: #57b947;
  border-color: #57b947;
  color: #ffffff;
  font-weight: bold;
}
.main-button[disabled] {
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  font-weight: bold;
}
</style>
