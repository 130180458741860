<template>
  <office-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
          <router-link to="/">
            <span>Home</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name: 'profile' }">
            <span>Profile</span>
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="Account Information">
        <UpdateProfile />
      </a-tab-pane>
      <a-tab-pane key="2" tab="Hotel Information">
        <FillUp actionType="edit" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="Change Password">
        <ChangePassword />
      </a-tab-pane>
    </a-tabs>
  </office-layout>
</template>

<script>
import { defineComponent } from "vue";
import { HomeOutlined } from "@ant-design/icons-vue";
import FillUp from "../../components/user/FillUp";
import UpdateProfile from "../../components/user/UpdateProfile";
import ChangePassword from "../../components/user/ChangePassword";

export default defineComponent({
  components: {
    HomeOutlined,
    FillUp,
    UpdateProfile,
    ChangePassword,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
